const taxRegimeData = [
    {
        label: "Lucro Presumido",
        value: "LUCRO_PRESUMIDO",
    },
    {
        label: "Lucro Real",
        value: "LUCRO_REAL",
    },
    {
        label: "Simples Nacional",
        value: "SIMPLES_NACIONAL",
    },
    {
        label: "Difal",
        value: "DIFAL",
    }
];

export default taxRegimeData;