import React from "react";
import Select from "react-select";
import { ErrorMessage, Field } from "formik";
import { taxRegimeData } from "../../forms/taxRegimeData";
import operationPurposeData from "../../forms/operationPurposeData";
import fiscalBenefitData from "../../forms/fiscalBenefitData";
import "../../styles/radio.css";

const TributariesDataForm = ({ values, setFieldValue, handleChange }) => {
    return (
        <div>
            <div className="overflow-auto h-70vh w-full pr-4">
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <div>
                            <label className="text-roxo_oficial font-bold  mt-4">Contribuinte do ICMS</label>
                            <div className="flex flex-col gap-2 border border-C2 rounded p-2">
                                <label className="container-radio">
                                    <input
                                        type="radio"
                                        id="isIcmsTaxpayer"
                                        onChange={handleChange}
                                        name="tributary.isIcmsTaxpayer"
                                        value={true}
                                        checked={
                                            values.tributary.isIcmsTaxpayer === "true" ||
                                                values.tributary.isIcmsTaxpayer === true
                                                ? true
                                                : false
                                        }
                                    />
                                    <span className="radio-mark"></span>
                                    Sim
                                </label>
                                <label className="container-radio">
                                    <input
                                        type="radio"
                                        id="isIcmsTaxpayer"
                                        onChange={handleChange}
                                        name="tributary.isIcmsTaxpayer"
                                        value={false}
                                        checked={
                                            values.tributary.isIcmsTaxpayer === "false" ||
                                                values.tributary.isIcmsTaxpayer === false
                                                ? true
                                                : false
                                        }
                                    />
                                    <span className="radio-mark"></span>
                                    Não
                                </label>
                                <ErrorMessage
                                    component="p"
                                    name="tributary.isIcmsTaxpayer"
                                    className="text-red text-xs font-light w-full"
                                />
                            </div>
                        </div>
                        <div>
                            <label className="text-roxo_oficial font-bold mt-4">
                                Finalidade da Operação <span className="text-C5 font-normal">(Cliente)</span>
                            </label>
                            <div className="flex flex-col gap-2 border border-C2 rounded p-2">
                                {operationPurposeData.map((item, index) => (
                                    <label key={index}>
                                        <input
                                            type="checkbox"
                                            onChange={handleChange}
                                            name="tributary.operationPurpose"
                                            value={item.value}
                                            checked={values.tributary.operationPurpose.includes(item.value)}
                                        />
                                        <span className="check-box-effect"></span>
                                        {item.label}
                                    </label>
                                ))}
                                <ErrorMessage
                                    component="p"
                                    name="tributary.operationPurpose"
                                    className="text-red text-xs font-light w-full"
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <label className="text-roxo_oficial font-bold mt-4">Benefício Fiscal</label>
                        <div className="flex flex-col gap-2 border border-C2 rounded px-2 py-4">
                            {fiscalBenefitData.map((item, index) => (
                                <label key={index}>
                                    <input
                                        type="checkbox"
                                        onChange={handleChange}
                                        name="tributary.fiscalBenefit"
                                        value={item.value}
                                        checked={values.tributary.fiscalBenefit.includes(item.value)}
                                    />
                                    <span className="check-box-effect"></span>
                                    {item.label}
                                </label>
                            ))}
                            <ErrorMessage
                                component="p"
                                name="tributary.fiscalBenefit"
                                className="text-red text-xs font-light w-full"
                            />
                        </div>
                    </div>
                </div>
                <label className="text-roxo_oficial font-bold mt-4">
                    Regime Tributário <span className="text-C5 font-normal">(Cliente)</span>
                </label>
                <Select
                    options={taxRegimeData}
                    name="tributary.taxRegime"
                    className="outline-none transform-none text-black"
                    placeholder="Selecione o regime tributário"
                    isMulti
                    value={values.tributary.taxRegime}
                    onChange={value => value && setFieldValue("tributary.taxRegime", value.value)}
                />
                <ErrorMessage component="p" name="tributary.taxRegime" className="text-red text-xs font-light w-full" />
                <label htmlFor="additionalInformation" className="flex text-roxo_oficial gap-1 font-bold mt-4">
                    Comentários Adicionais <span className="text-C5 font-normal ">(opcional)</span>
                </label>
                <Field
                    as="textarea"
                    rows="4"
                    className="w-full rounded-md border py-1 px-2 border-C2 mt-1"
                    name="tributary.additionalInformation"
                    id="additionalInformation"
                    onChange={handleChange}
                    value={values.tributary.additionalInformation}
                />
            </div>
        </div>
    );
};

export default TributariesDataForm;