const fiscalBenefitData = [
    {
        value: "TDD",
        label: "TDD"
    },
    {
        value: "FOUNDATION",
        label: "Fundap"
    },
    {
        value: "PRODEPE",
        label: "Prodepe"
    },
    {
        value: "IMPORT_CORRIDOR",
        label: "Corredor de Importação (MG)"
    },
    {
        value: "NÃO_SE_APLICA",
        label: "N/A"
    },
];
export default fiscalBenefitData;