const operationPurposeData = [
    {
        value: "ACTIVE_CONSUMPTION",
        label: "Ativo/Consumo"
    },
    {
        value: "INDUSTRIALIZATION_RESALE",
        label: "Industrialização/Revenda"
    }
];
export default operationPurposeData;